import React from 'react';
import Chart from "react-apexcharts";
import { Card } from 'reactstrap';
import './ProductChart.css';

function UserChart({ data, chartConfigs, statistics }) {

    const colorPalette = ['#00D8B6', '#008FFB', '#FEB019', '#FF4560', '#775DD0', '#fcf000'];

    // Function to prepare chart data
    const prepareChartData = (values, key) => {
        // Sort and map data for the chart
        const sortedData = values.sort((a, b) => b[key] - a[key]);

        return {
            series: sortedData.map(item => parseInt(item[key])),
            labels: sortedData.map(item => {
                return `${item.username}`;
            })
        };
    };

    // Function to generate chart options
    const getChartOptions = (title, series, labels, key) => {

        return {
            chart: {
                type: 'donut',
                width: '100%'
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val.toFixed(1) + "%"
                },
                dropShadow: {
                    enabled: false
                }
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: '10%',
                    },
                    offsetY: 1,
                },
                stroke: {
                    colors: undefined
                }
            },
            colors: colorPalette,
            title: {
                text: title,
                style: {
                    fontSize: '16px'
                }
            },
            series,
            labels,
            legend: {
                position: 'left',
                offsetY: 50
            }
        };
    };

    // Generate chart options for each configuration
    const charts = chartConfigs.map(({ key, title }) => {
        const { series, labels } = prepareChartData(data, key);
        return getChartOptions(title, series, labels, key);
    });

    // Reusable Chart component
    const renderChart = (options, index) => (
        <div key={index} className="item-statistics">
            <div className="box">
                <div id="">
                    <Chart
                        type={options.chart.type}
                        options={options}
                        series={options.series}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <Card>
            <div className="statistics-wrapper">
                {
                    charts.map((chartOptions, index) => renderChart(chartOptions, index))
                }
            </div>
        </Card>
    );
}

export default UserChart;