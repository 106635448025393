import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { accountActions } from '../../redux-modules/actions';
import ConfirmForm from './ConfirmPassword';
import './ForgotPassword.css';

function ForgotPassword({ registerPage, setRegisterPage, getQueryValue, history }) {
  const dispatch = useDispatch();
  const darkmode = false

  const [response, setResponse] = useState({
    success: true,
    message: '',
    active: false,
  });


  useEffect(() => {
      dispatch(accountActions.validateInvitation({
        username: getQueryValue('username'),
        token: getQueryValue('token')
      })).then(res => {
        if (res.status > 200) {
          setResponse({
            ...response,
            success: false,
            message: res.data[0].msg,
            active: true,
            validLink: false
          })
          setTimeout(() => {
            history.push('/login')
          }, 8000)
        }
      })
  }, [])

  useEffect(() => {
    return () => {
      dispatch(accountActions.clearError())
    }
  }, [])

  return (
    <div
      className={`
        ${'login'}
        ${registerPage ? null : 'active'}
        ${darkmode ? 'dark' : null}
      `}
    >
      <div className={'login-wrap'}>
        {
          response.success ?
          <>
            <div className={'header'}>
              <h2>Hello {getQueryValue('username')}</h2>
              <p>To continue adding your password</p>
              <p>Confirm your account & start a conversation with your store any + where/time.</p>
            </div>
            <ConfirmForm
              username = {getQueryValue('username')}
              token = {getQueryValue('token')}
              setRegisterPage={setRegisterPage}
              history={history}
            />
          </>
          :
          <div className={'header'}>
            <h2>Hello {getQueryValue('username')}</h2>
            <p>Oops! The invitation link you used is no longer valid.</p>
            <p>Please request a new one or contact support if you need assistance.</p>
           </div>
        }
        <div className={'footer'}>
            <p className={'text'}>Already have an account?</p>
            <button
                className={'btn'}
                onClick={setRegisterPage}
            >
                Login
            </button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
