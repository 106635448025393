import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { accountActions } from '../../redux-modules/actions';
import PasswordValidation from '../PasswordValidation';
import '../ConfirmRegister.css';

function ConfirmPassword({ setRegisterPage, username, token, history }) {
  const darkmode = false;
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const [control, setControl] = useState({
    password: false,
    confirmPassword: false,
  });

  const [formbody, setFormbody] = useState({
    password: '',
    confirmPassword: '',
  });

  const [response, setResponse] = useState({
    success: true,
    message: '',
    active: false
  });

  const handleChange = (event) => {
    setFormbody({
      ...formbody,
      [event.target.name]: event.target.value,
    });
  };


  useEffect(() => {
    if (account.err && account.err.length) {
      setResponse({
        ...response,
        success: false,
        message: account.err[0].msg,
        active: true,
      });
    } else if (account.activateInvitation) {
      setResponse((prev) => ({
        ...prev,
        success: true,
        message: "You have completed all stages of registration, now it's time to log in to your account",
        active: true,
      }));

      setTimeout(() => {
        setRegisterPage();
      }, 1500);
    }
  }, [account]);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (!control.password || !control.confirmPassword) {
        throw new Error('Form data is not approved, please fill in the form correctly');
      }

      dispatch(accountActions.activateInvitation({
        ...formbody,
        username: username,
        token: token,
      }, history));
    } catch (error0) {
      setResponse((prev) => ({
        ...prev,
        success: false,
        message: error0.message,
        active: true,
      }));
    }
  };

  useEffect(() => {
    const passValid = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*\W).{8,26}$/g;

    // Check if password is valid
    if (passValid.test(formbody.password)) {
      setControl((prev) => ({
        ...prev,
        password: true,
      }));
    } else {
      setControl((prev) => ({
        ...prev,
        password: false,
      }));
    }

    // Check if confirmPassword matches password
    if (formbody.password === formbody.confirmPassword && formbody.confirmPassword) {
      setControl((prev) => ({
        ...prev,
        confirmPassword: true,
      }));
    } else {
      setControl((prev) => ({
        ...prev,
        confirmPassword: false,
      }));
    }
  }, [formbody]);

  return (
    <form
      method="post"
      className={`${'form'} ${darkmode ? 'dark' : null}`}
      onSubmit={handleSubmit}
    >
      <label htmlFor="password-regis" className={'cards'}>
        <box-icon
          name={passwordShown ? "lock-open" : "lock"}
          color={darkmode ? '#ffffffdd' : '#000000dd'}
          onClick={() => setPasswordShown(!passwordShown)}
        />
        <span className={'input-field'}>
          <p className={'label'}>Password</p>
          <input
            type={passwordShown ? "text" : "password"}
            name="password"
            id="password-regis"
            placeholder="Must contain A-Za-z, 0-9 & symbols"
            value={formbody.password}
            onChange={handleChange}
            required
          />
        </span>
        <box-icon
          name={control.password ? 'check-circle' : 'x-circle'}
          color={`${control.password ? '#00A19D' : '#B91646'}`}
        />
      </label>

      <label htmlFor="confirm-password-regis" className={'cards'}>
        <box-icon
          name={confirmPasswordShown ? "lock-open" : "lock"}
          color={darkmode ? '#ffffffdd' : '#000000dd'}
          onClick={() => setConfirmPasswordShown(!confirmPasswordShown)}
        />
        <span className={'input-field'}>
          <p className={'label'}>Confirm Password</p>
          <input
            type={confirmPasswordShown ? "text" : "password"}
            name="confirmPassword"
            id="confirm-password-regis"
            placeholder="Confirm your password"
            value={formbody.confirmPassword}
            onChange={handleChange}
            required
          />
        </span>
        <box-icon
          name={control.confirmPassword ? 'check-circle' : 'x-circle'}
          color={`${control.confirmPassword ? '#00A19D' : '#B91646'}`}
        />
      </label>

      <PasswordValidation password={formbody.password} />
      <div className={`${'response'} ${response.active ? 'active' : null}`}>
        <box-icon
          name={`${response.success ? 'check-circle' : 'x-circle'}`}
          color={`${response.success ? '#00A19D' : '#B91646'}`}
        />
        <p>{response.message}</p>
      </div>
      <span className={'submit'}>
        <button type="submit" className={'btn'}>
          Submit
          <box-icon
            type="solid"
            name="right-top-arrow-circle"
            color={darkmode ? '#ffffffdd' : '#000000dd'}
          />
        </button>
      </span>
    </form>
  );
}

export default ConfirmPassword;