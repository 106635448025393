import  { CALL_TYPES } from '../../types'
import config from '../../../../config/app'
import axios from 'axios'
import qs from 'qs'

 class CallActions {
  constructor(_props) {
    this.api = config['CALL_API']
    this.rest  = 'call'
    this.arcive = 'b/'
  }

    pagination = (jwt, params, page, count, query) => {
          return async dispatch => {
            try {
              const searchString = qs.stringify({...query, showStatistics: true})
              const { data } = await axios.get(
                this.api + `${this.rest}/${params}` + page + '/' + count + '?' + searchString,
                {
                    headers: {
                      "Content-Type": "application/json",
                      'Authorization': `Bearer ${jwt}`
                    }
                })
                dispatch({
                    type: CALL_TYPES.CALL_PAG_F,
                    payload: data
                })
            } catch (e) {
                dispatch({
                    type: CALL_TYPES.CALL_PAG_R,
                    payload: e
                })
            }
        }
    }

    update = (jwt, body, Type, history) => {
      return async dispatch => {
        try {
            await axios.put(
            this.api+ `${this.rest}/${body.id}`,
            { 
              status: body.status, 
            },
            {
                headers: 
                  {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
            })

            dispatch({
                type: CALL_TYPES[Type + '_UPDATE_F'],
                payload: body
            })

              
            history.goBack()
            
        } catch (e) {

            dispatch({
                type: CALL_TYPES[Type + '_UPDATE_R'],
                payload: e
            })

          }
        }
    }

    destroy = (jwt, id, Type) => {
      return async dispatch => {
        try {
            await axios.delete(
            this.api + `${this.rest}/${id}`,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )
            dispatch({
                type: CALL_TYPES[Type + '_DELETE_F'],
                payload: id
            })
        } catch (e) {
            dispatch({
                type: CALL_TYPES[Type + '_DELETE_R'],
                payload: e
            })
          }
        }
    }

    blockOrUnblock = (jwt, phone, Type) => {
      return async dispatch => {
        try {
            await axios.post(
            this.api + `${this.rest}/block_or_unblock`,
              {
                phone: phone
              },
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )
            dispatch({
                type: CALL_TYPES[Type + '_BLOCK_OR_UNBLOCK_F'],
                payload: phone
            })
        } catch (e) {
            dispatch({
                type: CALL_TYPES[Type + '_BLOCK_OR_UNBLOCK_R'],
                payload: e
            })
          }
        }
    }

    getOne = (jwt, id, Type) => {
      return async dispatch => {
        try {

          dispatch({
            type: CALL_TYPES[Type + '_GET_ONE_P']
          })

          const response = await axios.get(
            this.api + `${this.rest}/${id}`,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            }
          )

          dispatch({
            type: CALL_TYPES[Type + '_GET_ONE_F'],
            payload: response.data
          })

          return response.data
            
        } catch (e) {

            dispatch({
              type: CALL_TYPES[Type + '_GET_ONE_R'],
              payload: e
            })

          }
        }
    }

    /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
     */
      bulk = (ids, jwt, params, otherDispatchParams) => {
        return async dispatch => {
          try {
             await axios.post(
              this.api + `${this.rest}/` + this.arcive + params,
              ids,
              {
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
              }
            )
              dispatch({
                  type: CALL_TYPES.CALL_BULK_ARCIVE_F,
                  payload: ids
              })
              dispatch(this.pagination(...otherDispatchParams))
              
          } catch (e) {

              dispatch({
                  type: CALL_TYPES.CALL_BULK_ARCIVE_R,
                  payload: e
              })

            }
          }
      }

          /**
     * @name bulk
     * @param elem
     * @returns {function(*)}
     */
    bulkDestroy = (ids, jwt, _params, otherDispatchParams) => {
      return async dispatch => {
        try {
           await axios.post(
            this.api + `${this.rest}/b/d`,
            ids,
            {
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${jwt}`
                }
            }
          )
            dispatch({
                type: CALL_TYPES.CALL_BULK_DESTROY_F,
                payload: ids
            })
            dispatch(this.pagination(...otherDispatchParams))
            
        } catch (e) {

            dispatch({
                type: CALL_TYPES.CALL_BULK_DESTROY_R,
                payload: e
            })

          }
        }
    }

    create = (jwt, body, Type) => {

      return async dispatch => {
        try {
            const response = await axios.post(
            this.api+ `${this.rest}`,
            body,
            {
                headers: 
                  {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${jwt}`
                  }
            })

            dispatch({
              type: CALL_TYPES[Type + '_CREATE_F'],
              payload: body
            })

            return response

        } catch (e) {

            dispatch({
                type: CALL_TYPES[Type + '_CREATE_R'],
                payload: e
            })
          
            return e.response

          }
        }
    }

    checkProvider = (jwt, body, Type) => {

        return async dispatch => {
          try {
                const response = await axios.post(
                this.api+ `${this.rest}/provider`,
                body,
                {
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${jwt}`
                    }
                })
    
                dispatch({
                    type: CALL_TYPES[Type + '_CHECK_PROVIDER_F'],
                    payload: response.data
                })

                return response.data
  
          } catch (e) {
  
              dispatch({
                  type: CALL_TYPES[Type + '_CHECK_PROVIDER_R'],
                  payload: e
              })
            
              return e.response
  
            }
          }
      }

    getStatistics = (jwt, query) => {
      return async dispatch => {
        try {

          const searchString = qs.stringify(query)

          dispatch({
            type: CALL_TYPES.GET_CALL_STATISTICS_P,
          })

          const response = await axios.get(`${this.api + `${this.rest}/s?${searchString}`}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${jwt}`
            }
          })

          dispatch({
            type: CALL_TYPES.GET_CALL_STATISTICS_F,
            payload: response.data
          })

        } catch (e) {
          dispatch({
            type: CALL_TYPES.GET_CALL_STATISTICS_R,
            payload: e
          })
        }
      }
    };

    callRecord = (jwt, phone) => {
      return async dispatch => {
        try {

          dispatch({
            type: CALL_TYPES.CALL_RECORD_P
          })
  
          const { data } = await axios.get(
            `https://handler.projectnulla.info/twilio/call/logs?phone=${phone}`,
            {
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${jwt}`
              }
            }
          )

          dispatch({
            type: CALL_TYPES.CALL_RECORD_F,
            payload: data[0].data
          })
  
        } catch (e) {

          dispatch({
            type: CALL_TYPES.CALL_RECORD_R,
            payload: e
          })
  
        }
      }
    };

    clear = () => {
      return async dispatch => {
        dispatch({
          type: CALL_TYPES.CALL_CLEAR_ONE
        })
      }
    }

}




export default new CallActions()