const SIGN_IN = 'SIGN_IN';
const SIGN_OUT = 'SIGN_OUT';
const SIGN_UP = 'SIGN_UP';
const FETCH_USER_PERMISSIONS_P = 'FETCH_USER_PERMISSIONS_P'
const FETCH_USER_PERMISSIONS_F = 'FETCH_USER_PERMISSIONS_F'
const FETCH_USER_PERMISSIONS_R = 'FETCH_USER_PERMISSIONS_R'
const SIGN_UP_P = 'SIGN_UP_P';
const SIGN_UP_F = 'SIGN_UP_F';
const SIGN_UP_R = 'SIGN_UP_R';
const VERIFY_P = 'VERIFY_P';
const VERIFY_F = 'VERIFY_F';
const VERIFY_R = 'VERIFY_R';
const FORGOT_PASSWORD_P = 'FORGOT_PASSWORD_P'
const FORGOT_PASSWORD_F = 'FORGOT_PASSWORD_F'
const FORGOT_PASSWORD_R = 'FORGOT_PASSWORD_R'
const RESET_PASSWORD_P = 'RESET_PASSWORD_P'
const RESET_PASSWORD_F = 'RESET_PASSWORD_F'
const RESET_PASSWORD_R = 'RESET_PASSWORD_R'
const CLEAR_ERROR = 'CLEAR_ERROR'
const RESEND_CODE_P = 'RESEND_CODE_P'
const RESEND_CODE_F = 'RESEND_CODE_F'
const RESEND_CODE_R = 'RESEND_CODE_R'
const ACTIVATE_INVITATION_P = 'ACTIVATE_INVITATION_P'
const ACTIVATE_INVITATION_F = 'ACTIVATE_INVITATION_F'
const ACTIVATE_INVITATION_R = 'ACTIVATE_INVITATION_R'
const VALIDATE_INVITATION_P = 'VALIDATE_INVITATION_P'
const VALIDATE_INVITATION_F = 'VALIDATE_INVITATION_F'
const VALIDATE_INVITATION_R = 'VALIDATE_INVITATION_R'

export {
    SIGN_IN, 
    SIGN_OUT, 
    SIGN_UP,
    FETCH_USER_PERMISSIONS_P,
    FETCH_USER_PERMISSIONS_F,
    FETCH_USER_PERMISSIONS_R,
    SIGN_UP_P,
    SIGN_UP_F,
    SIGN_UP_R,
    VERIFY_P,
    VERIFY_F,
    VERIFY_R,
    FORGOT_PASSWORD_P,
    FORGOT_PASSWORD_F,
    FORGOT_PASSWORD_R,
    RESET_PASSWORD_P,
    RESET_PASSWORD_F,
    RESET_PASSWORD_R,
    CLEAR_ERROR,
    RESEND_CODE_P,
    RESEND_CODE_F,
    RESEND_CODE_R,
    ACTIVATE_INVITATION_P,
    ACTIVATE_INVITATION_F,
    ACTIVATE_INVITATION_R,
    VALIDATE_INVITATION_P,
    VALIDATE_INVITATION_F,
    VALIDATE_INVITATION_R
}