import React, {Component} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {Container} from 'reactstrap';

import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import NavigationData from '../../_nav';
// routes config
import routes from '../../routes';
import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import {languageActions, accountActions} from "../../projectNulla/redux-modules/actions";
import {connect} from "react-redux";
import { updateDimensions } from '../../projectNulla/redux-modules/services/mobileService';
import Phone from '../../projectNulla/call/Phone';
import Call from '../../projectNulla/chat/Call';
const isMobile = updateDimensions()

class DefaultLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  LogOut() {
    this.props.signOut()
    this.props.history.push('/login');
  }

  insertArrayAt(array, index, arrayToInsert) {
    Array.prototype.splice.apply(array, [index, 0].concat(arrayToInsert));
    return array;
  }

  getNavData() {
    const { account } = this.props;
    const navData = new NavigationData()
    let navigation = navData.getData()
    const permissions = this.props.permissions.filter(({type}) => type === "access")

    if (isMobile) {
      navigation.items = [
        ...navigation.items,
        {
          name: 'Logout',
          url: '/login',
          icon: 'fa fa-lock',
          attributes: {
            onClick: e => {
              e.preventDefault();
              e.stopPropagation();
              this.LogOut();
            }
          }
        }
      ]
    }

    navigation.items.splice(1, 0, {
      name: account.user.group.name,
      url: '/dashboard',
      badge: {
        variant: 'info',
        text: account.user.username,
      }
    })
 
    navigation.items.forEach((elem, index) => {

      if (elem.children && elem.children.length) {
        const array = []
        elem.children.forEach((entry1) => {

          if (entry1.children && entry1.children.length) {
            entry1.children = entry1.children.filter(entry3 => permissions.some(entry2 => entry3.url.slice(1) === entry2.value))
          }

          if (permissions.find(entry2 => entry1.url.slice(1) === entry2.value) || (entry1.children && entry1.children.length)) {
            array.push(entry1)
          }
          
        })

        navigation.items[index].children = array

      } 
      
      if (navigation.items[index] && navigation.items[index].children && !navigation.items[index].children['length']) {

        delete navigation.items[index]

      }

    })

    return navigation

  }

  getRoutes() {
    const array = routes.filter(entry1 => 
      this.props.permissions.some(entry2 => {
        if  (entry1.path.slice(1) === entry2.value || entry1.path === '/dashboard') {
          return true
        }

        if (entry2.type == "modify" && entry1.path.slice(1, entry1.path.lastIndexOf('/'))  === entry2.value) {
          return true 
        }
        let newStr = '/' + entry1.path.slice(1, entry1.path.lastIndexOf('/'))
        if (entry2.type == "modify" &&  newStr.slice(1, newStr.lastIndexOf('/')) === entry2.value ) {
          return true
        }

      })
    )
    return array.map((route, index) => <Route key={ index } path={route.path} exact={route.exact} name={route.name} render = {props => ( <route.component {...props} /> )}/>)
  }


  render() {
    const { permissions, account } = this.props
    return (
      <React.Fragment>
        <div className="app">
          <AppHeader fixed>
            <DefaultHeader/>
          </AppHeader>
          <div id="#app-body" className="app-body">
            <AppSidebar fixed display="lg">
              <AppSidebarHeader/>
              <AppSidebarForm/>
              {
                !!permissions.length &&
                <AppSidebarNav 
                  navConfig={this.getNavData()} {...this.props} 
                />
              }
              <AppSidebarFooter/>
              <AppSidebarMinimizer/>
            </AppSidebar>
            <main className="main">
              {
                !this.props.location.pathname.includes('/delivery/delivery') &&
                <AppBreadcrumb appRoutes={routes}/>
              }
              <Container fluid>
                {
                  !!permissions.length &&
                  <Switch>
                    { this.getRoutes() }
                    <Redirect from="/" to="/dashboard"/>
                  </Switch>
                }
              </Container>
            </main> 
            <AppAside fixed>
              <DefaultAside {...this.props}/>
            </AppAside>
          </div>
          {
            !this.props.location.pathname.includes('/delivery/delivery') &&
            <AppFooter>
              <DefaultFooter/>
            </AppFooter>
          }
        </div> 
        <Phone
          showKeypad={false} 
          loginTelnyx={false}
        /> 
        {
          <Call
            account={account}
          />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    permissions: state.account.userGroup.permissions,
    account: state.account
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLanguages: () => dispatch(languageActions.getLanguages()),
    signOut: () => dispatch(accountActions.signOut()),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DefaultLayout));
