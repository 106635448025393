import React, { useState } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Progress,
    Table,
} from 'reactstrap';
import moment from 'moment';
import './ProductChart.css';

// Utility Functions
const getPercentage = (numerator, denominator) => {
    return denominator == 0 ? 0 : ((numerator / denominator) * 100).toFixed(1);
};

function timeSince(date) {
    const NOW = new Date();
    const times = [["second", 1], ["minute", 60], ["hour", 3600], ["day", 86400], ["week", 604800], ["month", 2592000], ["year", 31536000]];
    let diff = Math.round((NOW - new Date(date)) / 1000);
    for (let t = 0; t < times.length; t++) {
        if (diff < times[t][1]) {
            if (t === 0) {
                return "Just now";
            } else {
                diff = Math.round(diff / times[t - 1][1]);
                return diff + " " + times[t - 1][0] + (diff === 1 ? " ago" : "s ago");
            }
        }
    }
}

// ProgressItem Component to Render Each Reason
const ProgressItem = ({ label, value, total, color }) => (
    <>
        <div className="clearfix">
            <div className="float-left">
                <strong>{getPercentage(value, total)}%</strong>
            </div>
            <div className="float-right">
                <small className="text-muted">{label} {value}</small>
            </div>
        </div>
        <Progress className="progress-xs" color={color} value={getPercentage(value, total)} />
    </>
);

function StaffChart({ data }) {
    const [cancelationReasons, setCancelationReasons] = useState([]);

    const showCancelationReason = (id) => {
        setCancelationReasons((prevReasons) =>
            prevReasons.includes(id)
                ? prevReasons.filter((elemId) => elemId !== id)
                : [...prevReasons, id]
        );
    };

    return (
        <Card>
            <CardHeader>
                Staff Sales
            </CardHeader>
            <CardBody>
                <br />
                <Table hover responsive className="table-outline mb-0 d-none d-sm-table">
                    <thead className="thead-light">
                        <tr>
                            <th className="text-center"><i className="icon-people"></i></th>
                            <th>User</th>
                            <th>Done ({data.done})</th>
                            <th>Cancel ({data.cancel})</th>
                            <th>Activity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.staffStatistics.map((elem, i) => (
                            <tr key={i}>
                                <td className="text-center top-align">
                                    <div className="avatar">
                                        <img src={'assets/img/avatars/default.jpg'} className="img-avatar" alt="img-avatar" />
                                        <span className={`avatar-status ${elem.online ? 'badge-success' : 'badge-danger'}`}></span>
                                    </div>
                                </td>
                                <td className='top-align'>
                                    <div>{elem.firstname} {elem.lastname}</div>
                                    <div className="small text-muted">
                                        Registered: {moment(elem.dateAdded).format('MMMM Do YYYY, h:mm:ss a')}
                                    </div>
                                </td>
                                <td className='top-align'>
                                    <ProgressItem
                                        label={''}
                                        value={elem.done}
                                        total={elem.totalCount}
                                        color="success"
                                    />
                                </td>
                                <td className='top-align'>
                                    <div onClick={() => showCancelationReason(elem.userId)}>
                                        <ProgressItem
                                            label={''}
                                            value={elem.cancel}
                                            total={elem.totalCount}
                                            color="warning"
                                        />
                                    </div>
                                    {/* Dropdown for cancellation reasons */}
                                    {cancelationReasons.includes(elem.userId) && (
                                        <div className="mt-2">
                                            {[
                                                { label: 'Long Eta', value: elem.longEta },
                                                { label: 'Customer No Show', value: elem.customerNoShow },
                                                { label: 'Total Too Expensive', value: elem.totalTooExpensive },
                                                { label: 'Med Rec Issue', value: elem.iDOrMedRecIssue },
                                                { label: 'Out Of Stock', value: elem.outOfStock },
                                                { label: 'Outside Delivery Zone', value: elem.outsideDeliveryZone },
                                                { label: 'No Response', value: elem.noResponse },
                                                { label: 'Other Notes', value: elem.otherNotes },
                                            ].map((reason, index) => (
                                                <div key={index} className="mt-2">
                                                    <ProgressItem
                                                        key={index}
                                                        label={reason.label}
                                                        value={reason.value}
                                                        total={elem.cancel}
                                                        color="danger"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </td>
                                <td className='top-align'>
                                    <div className="small text-muted">Last visit</div>
                                    <strong>{timeSince(elem.dateOnline)}</strong>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
}

export default StaffChart;
