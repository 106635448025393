import React, { useEffect, useState, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux'
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import {
    callActions, businessActions
  } from "../../projectNulla/redux-modules/actions";
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    ButtonToolbar,
    CardTitle,
    Col,
    Row
} from 'reactstrap';
import { DatePicker } from '../../projectNulla/catalogs/Globals';
import ReactSelect from 'react-select';
const brandInfo = getStyle('--info')
const statusColors = {
    all: brandInfo,
    busy: brandInfo,
    uniqueOutbound: '#20a8d8',
    cancel: '#2f353a',
    missed: '#f86c6b',
    answerd: '#acb5bc',
    inbound: '#ffc107',
    uniqueInbound: '#3a9d5d'
}

const status = ['all', 'answerd', 'missed', 'uniqueInbound', 'uniqueOutbound', 'inbound', 'busy'];

function CallChart(_props) {
    const dispatch = useDispatch()
    const selectRef = useRef([]);
    const [selectedBusiness, setSelectedBusines] = useState([])
    const [closeOnSelectBusiness, setCloseOnSelectBusiness] = useState(false)
    const [page, setPage] = useState(1)
    const { statistics } = useSelector((state) => state.call);
    const { count: businessCount, businessSelect } = useSelector((state) => state.business);
    const maxCount = Math.max(...statistics.data.map(o => o.count));

    const mainChartOpts = {
        tooltips: {
            enabled: false,
            custom: CustomTooltips,
            intersect: true,
            mode: 'index',
            position: 'nearest',
            callbacks: {
                    labelColor: function(tooltipItem, chart) {
                    return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
                }
            }
        },
        maintainAspectRatio: false,
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
                {
                    display: false,
                    gridLines: {
                        drawOnChartArea: false,
                    },
                }
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        stepSize: Math.ceil(maxCount / 5),
                        max:  maxCount
                    },
                }
            ],
        },
        elements: {
            point: {
                radius: 0,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3,
            },
        },
    };

    const { token } = useSelector((state) => state.account);
    const [date, setDate] = useState({ startDate: '', endDate: '' })
    const [radioSelected, setRadioSelected] = useState('week');

    const selectBusiness = (selectedOption) => {
        let selectedOptions = []
        if (selectedOption.length) {
            selectedOption.forEach(elem => {
                if (elem.value === 'all') {
                    let businessPage = page + 1
                    setPage(businessPage)
                    setCloseOnSelectBusiness(false)
                    dispatch(businessActions.seeAll(token, businessPage, 10))
                }  else {
                    selectedOptions.push(elem)
                }
            })
        }
        setSelectedBusines(selectedOptions)
    }

    useEffect(() => {
        dispatch(callActions.getStatistics(token, { 
          startDate: date.startDate,
          endDate: date.endDate,
          date: radioSelected,
          businessIds: selectedBusiness.map(({value}) => value)
        }));
    }, [date, radioSelected, date, selectedBusiness])

    function onRadioBtnClick(value) {
        setRadioSelected(value)
    }
    
    const getClientLanguage = () => {
        let Locale = navigator.language || navigator.userLanguage;
        if (Locale.includes('-')) Locale = Locale.split('-')[0];
        return Locale.toLowerCase();
    };

    const genericFormattedDate = (DateToFormat) => {
        const UserLanguage = getClientLanguage();
        const Config = { year: 'numeric', month: 'long', hour: '2-digit', minute:'2-digit', day: '2-digit' };
        return new Date(DateToFormat).toLocaleString(UserLanguage, Config);
    };

    const mainChart = {
        labels: statistics.data.map(d => genericFormattedDate(d.date)),
        datasets: status.map(item => {
            return ({
                label: item,
                fill: item == 'all',
                lineTension: 0.4,
                backgroundColor: hexToRgba(brandInfo, 10),
                borderColor: statusColors[item], // The main line color
                borderCapStyle: 'square',
                borderDash: [], // try [5, 15] for instance
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: statusColors[item],
                pointBackgroundColor: "white",
                pointBorderWidth: 1,
                pointHoverRadius: 4,
                pointHoverBackgroundColor: "yellow",
                pointHoverBorderColor: "brown",
                pointHoverBorderWidth: 1,
                pointRadius: 2,
                pointHitRadius: 5,
                data: statistics.data.map(d => d[item] || 0)
            })
        })
    };


    function handleChangeDate (event, key) {
        setDate({
            ...date,
            [key]: event.target.value
        })
    }

    function openMenu(index) {
        if (index == 0) {
            if (!businessSelect.length) {
                dispatch(businessActions.seeAll(token, 1, 10));  
            }
        }
        selectRef.current[index].focus()
    }

    const handleInputChangeBusiness = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '');
        dispatch(businessActions.seeAll(token, 1, 10, { name: inputValue }))
    };
    
    return (
        <Card>
             <CardBody>
                <Row>
                    <Col sm="2">
                        <CardTitle className="mb-0">Calls</CardTitle>
                         <div className="small text-muted">{genericFormattedDate(new Date())}</div>
                    </Col>
                    <Col sm="5">
                        <DatePicker
                            handleChange={handleChangeDate}
                            value={{
                                startDate: date.startDate,
                                endDate: date.endDate 
                            }}
                        />
                    </Col>
                    <Col sm="2">
                        <div style={{width: "200px"}}>
                            <ReactSelect
                                ref={el => selectRef.current[0] = el}
                                onFocus={() => openMenu(0)}
                                closeOnSelect = {closeOnSelectBusiness}
                                name="form-field-name"
                                value={selectedBusiness}
                                onInputChange={handleInputChangeBusiness}
                                multi = {true}
                                onChange={selectBusiness}
                                options={
                                    businessCount > businessSelect.length 
                                    ? [...businessSelect, { value: 'all', label: 'See More'}]
                                    : [...businessSelect]
                                }
                                placeholder={<div>Business</div>}
                            />
                        </div>
                    </Col>
                    <Col sm="3" className="d-none d-sm-inline-block">
                        {/* <Button color="primary" className="float-right"><i className="icon-cloud-download"></i></Button> */}
                        <ButtonToolbar className="float-right" aria-label="Toolbar with button groups">
                            <ButtonGroup className="mr-3" aria-label="First group">
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('minute')} active={radioSelected === 'minute'}>Minute</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('hour')} active={radioSelected === 'hour'}>Hour</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('day')} active={radioSelected === 'day'}>Day</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('week')} active={radioSelected === 'week'}>Week</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('month')} active={radioSelected === 'month'}>Month</Button>
                            <Button color="outline-secondary" onClick={() => onRadioBtnClick('year')} active={radioSelected === 'year'}>Year</Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Col>
                </Row>
                <div className="chart-wrapper" style={{ height: 300 + 'px', marginTop: 40 + 'px' }}>
                    <Line data={mainChart} options={mainChartOpts} height={300} />
                </div>
            </CardBody>
            <CardFooter>
                <Row className="text-center">
                  <Col sm={12} md className="mb-sm-2 mb-0">
                    <div className="text-muted">Calls</div>
                    <strong>{statistics.calls} Calls</strong>
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0 d-md-down-none">
                    <div className="text-muted">Unique</div>
                    <strong>{statistics.unique} Calls</strong>
                  </Col>
                </Row>
            </CardFooter>
        </Card>
    )
}

export default CallChart