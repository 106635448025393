import React from 'react';
import { useLocation } from "react-router-dom";
import Register from './Registration/Register';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import UserActivate from './UserActivate/UserActivate'
import  './Auth.css';

function Auth(props) {
  const { history, location } = props;
  const { search } = useLocation();
  const getQueryValue = (key) => new URLSearchParams(search).get(key) || '';
  const darkmode = false

  /**
    * @param {Object} query
  */
   const addQuery = (query) => {

    let pathname = history.location.pathname;
    let searchParams = new URLSearchParams(history.location.search);

    Object.keys(query).forEach(key => {
        searchParams.set(key, query[key])
    })

    history.push({
        pathname: pathname,
        search: searchParams.toString()
    });

  };

  const deleteQuery = (key) => {
    const queryParams = new URLSearchParams(history.location.search)
    if (queryParams.has(key)) {
      queryParams.delete(key)
      history.replace({
        search: queryParams.toString(),
      })
    }
  }


  const handleRegisterPage = () => history.push('/login');

  return (
    <div className={`${'auth'} ${darkmode ? 'dark' : null}`}>
      <div className={'auth-main'}>
        {
          location.pathname == '/register' ?
          <Register
            addQuery={addQuery}
            deleteQuery={deleteQuery}
            getQueryValue={getQueryValue}
            setRegisterPage={handleRegisterPage}
          />
          :
          location.pathname == '/user/activate' ?
          <UserActivate
            addQuery={addQuery}
            deleteQuery={deleteQuery}
            getQueryValue={getQueryValue}
            setRegisterPage={handleRegisterPage}
            history={history}
          />
          :
          <ForgotPassword
            addQuery={addQuery}
            deleteQuery={deleteQuery}
            getQueryValue={getQueryValue}
            setRegisterPage={handleRegisterPage}
          />
        }
      </div>
    </div>
  );
}

export default Auth;
